<template>
    <div class="d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden">
        <v-card v-if="!allInfoLoaded">
            <v-skeleton-loader
                type="card, list-item-two-line, divider, list-item-avatar-three-line, divider, list-item-avatar-three-line, divider, actions"
                width="350"></v-skeleton-loader>
        </v-card>
        <v-card v-else width="350">
            <v-card-title class="justify-center align-center nuvolos-header">
                <v-img max-width="200" max-height="22" class="py-5" src="@/assets/Nuvolos-transparent.svg" />
            </v-card-title>

            <template v-if="validInvitation">
                <h2 class="secondary--text text-center text-h6 mt-5">Join Nuvolos</h2>
                <v-card-text>You have received invitation(s) to Nuvolos. Please review the grants and continue if you wish to accept.</v-card-text>
                <v-subheader>REVIEW GRANTS</v-subheader>
                <v-divider></v-divider>
                <v-subheader>Organization</v-subheader>
                <v-card-text class="pt-0">
                    <v-card flat class="grey lighten-3">
                        <v-card-text class="d-flex align-center">
                            <b>{{ roles[0].Organization }}</b>
                            <v-spacer></v-spacer>
                            <v-chip small outlined color="primary">
                                {{ orgRole }}
                            </v-chip>
                        </v-card-text>
                    </v-card>
                </v-card-text>
                <v-divider></v-divider>

                <template v-if="spaceRole">
                    <v-subheader>Space</v-subheader>
                    <v-card-text class="pt-0">
                        <v-card flat class="grey lighten-3">
                            <v-card-text class="d-flex align-center">
                                <b>{{ spaceRole.Space }}</b>
                                <v-spacer></v-spacer>
                                <v-chip v-if="spaceRole" small outlined color="primary">
                                    {{ spaceRole.Name }}
                                </v-chip>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-divider />
                </template>

                <template v-if="spaceInvites.length > 0">
                    <v-subheader>Space</v-subheader>
                    <v-card-text class="pt-0">
                        <v-card flat class="grey lighten-3">
                            <v-card-text class="d-flex align-center">
                                <b>{{ spaceInvites[0].Space }}</b>
                            </v-card-text>
                            <v-divider></v-divider>
                            <template v-if="spaceInvites.length > 0">
                                <v-list-item v-for="(r, index) in spaceInvites" :key="`grants-${index}`">
                                    <v-list-item-content>
                                        <v-list-item-subtitle v-for="(value, name) in user_pretty(r)" :key="name" class="text-truncate">
                                            {{ name }}:
                                            <b class="secondary--text">{{ value }}</b>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider v-if="index < spaceInvites.length - 1" :key="`divider-${index}`"></v-divider>
                            </template>
                        </v-card>
                    </v-card-text>
                    <v-divider />
                </template>

                <v-card-text>
                    <router-view></router-view>
                </v-card-text>
            </template>

            <v-card-text v-else class="pt-5">
                <v-alert dense text type="error">We are sorry but this invitation is no longer valid.</v-alert>
                <v-card-actions>
                    <v-btn elevation="0" block color="primary" :to="{ name: 'login' }">Sign in to Nuvolos</v-btn>
                </v-card-actions>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'invitationIndex',
    components: {},
    data() {
        return {
            validInvitation: false,
            roles: '',
            invitationStatus: '',
            loadingInvitation: true,
            isTrial: false,
            emailIsRegistered: false,
            email: ''
        }
    },
    created() {
        this.getInvitation()
    },
    computed: {
        allInfoLoaded() {
            return !this.loadingInvitation && !this.$auth.loading
        },
        orgRole() {
            return this.roles.find(role => role.role_type === 'OrgRoleType')?.Name
        },
        spaceRole() {
            return this.roles.find(role => role.role_type === 'SpaceRoleType')
        },
        spaceInvites() {
            return this.roles.filter(role => role.role_type === 'InstanceRoleType')
        }
    },
    methods: {
        async getInvitation() {
            try {
                const { data } = await this.$axios.get(`/invitations/describe/${this.$route.params.invid}`)
                this.roles = data.roles
                this.validInvitation = data.valid === 'True'
                this.email = data.requestee_email

                const { data: loginMethods } = await this.$axios.post('/users/login_methods', { email: this.email })
                if (loginMethods?.methods.length) {
                    this.emailIsRegistered = true
                } else {
                    this.emailIsRegistered = false
                }

                this.$store.dispatch('userStore/setSignupUserDetails', { email: this.email })
                this.isTrial = data.trid !== undefined && data.trid !== null
                this.invitationStatus = data.status_name
            } catch (error) {
                if (error?.response?.status >= 500) {
                    this.$store.dispatch('showGlobalDialog', {
                        dialogTitle: 'Temporary issue',
                        dialogText: 'Something went wrong, please try to refresh this page in a few minutes.',
                        dialogAction: ['reload']
                    })
                }
                this.validInvitation = false
            }
            this.loadingInvitation = false
        },
        user_pretty(r) {
            const prettyInvite = {}
            if (r.Instance) {
                prettyInvite.Instance = r.Instance
            }
            if (r.Name) {
                prettyInvite.Role = r.role_type === 'ResourcePoolRoleType' ? `RESOURCE POOL ${r.Name}` : r.Name
            }
            return prettyInvite
        },
        loginAndReturn() {
            localStorage.setItem('nvLastEmail', this.email)
            this.$router.push({ name: 'login', query: { targetUrl: this.$route.path } })
        }
    },
    watch: {
        allInfoLoaded() {
            if (this.emailIsRegistered && this.validInvitation) {
                if (!this.$auth.isAuthenticated) {
                    this.loginAndReturn()
                } else {
                    this.$router.push({ name: 'invitation-authenticated', params: { invid: this.$route.params.invid } })
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/signup.scss';
</style>
